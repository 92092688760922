<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">商品清倉</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="permission.isCreate"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增申請
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input
              type="search"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              clearable="true"
              @keyup="
                (e) => {
                  if (e.$event.keyCode === 13) grid.refresh();
                }
              "
              @clear="grid.refresh()"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0"></div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              :title-width="100"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}
.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script>
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import formatDate from "xe-utils/toDateString";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const permission = reactive({
      isCreate: true, // model?.user.Permissions.includes("BaseDataCreate"),
      isEditing: true, // model?.user.Permissions.includes("BaseDataUpdate"),
      isDelete: true, // model?.user.Permissions.includes("BaseDataDelete"),
    });

    const gridOptions = {
      title: "文章",
      canCreateRow: permission.isCreate,
      canUpdateRow: permission.isEditing,
      canDeleteRow: permission.isDelete,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "Title",
          title: "商品名稱/敘述",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          //   filters: [{ checked: false, label: "包含" }],
          //   slots: { filter: "name_filter" },
        },
        // {
        //   field: "Published",
        //   title: "發佈",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   resizable: false,
        //   formatter: ({ cellValue }) => (cellValue === 0 ? "否" : "是"),
        // },
        {
          field: "StartDate",
          title: "活動起始日",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd"),
        },
        {
          field: "EndDate",
          title: "活動截止日",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd"),
        },
        {
          field: "PublishDate",
          title: "預計上架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd"),
        },
        {
          field: "DataMode",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          resizable: false,
          formatter: ({ cellValue }) => "待確認",
        },
        // {
        //   field: "StartDateString",
        //   title: "發佈開始日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   resizable: false,
        // },
        // {
        //   field: "EndDateString",
        //   title: "發佈結束日期",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   resizable: false,
        // },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch("news/queryProductTest", params)
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("news/queryProductTest", {
                keyword: grid.value.keyword,
              })
          : undefined,
        save: model
          ? (params) => {
              if (permission.isEditing || permission.isCreate)
                return model.dispatch("news/save", params);
              else return Promise.resolve();
            }
          : undefined,
      },
      modalConfig: { height: "100%", width: "100%" },
    };
    const formOptions = {
      items: [
        {
          field: "Number",
          title: "編號",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入標籤編號",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入標籤名稱",
              disabled: !permission.isEditing,
            },
          },
        },

        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入編號",
          },
        ],
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入名稱",
          },
        ],
      },
    };

    const initData = (row) => {
      row.Id = 0;
      row.Name = "";
      row.Number = "";
    };

    return {
      initData,
      grid,
      gridOptions,
      formOptions,
      permission,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("news/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
  },
});
</script>
